<template>
  <div class="cadastro-usuario">
    <v-container fluid>
      <h1 class="titulo">Zera Saldo</h1>
      <v-row justify="end">
        <v-btn @click="submit" :loading="isLoading" color="info">Zerar os saldos</v-btn>
      </v-row>
    </v-container>

     <div class="box-table">
        <v-skeleton-loader
            :loading="loadTable"
            :transition="'fade-transition'"
            height="500"
            type="table">
          <v-data-table
            :headers="headers"
            :items="getData"
            width="300px"
            flat
            dense
            :items-per-page="getData.length"> 
            <template slot="body.prepend">
              <tr>
                <th colspan="2">Total de revenda: {{ totalRevenda }}</th>
                <th>Total valor: {{ totalValorZeraSaldo | currency }}</th>
                <!-- <th>teste</th> -->
              </tr>
            </template>

             <template v-slot:item.deve_atual="{ item }">
                {{ item.deve_atual | currency }}
              </template>

          </v-data-table>
        </v-skeleton-loader>
      </div>

    <v-snackbar v-model="snackbar.toggle" top right :color="snackbar.type">{{ snackbar.msg }}
      <v-btn dark text @click="snackbar.toggle = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { size } from 'lodash'
import { errorSnackbar, successSnackbar, currency } from '@/core/service/utils'
// import listagemTables from '@/views/mixins/listagemTables'

const storeNameSpace = 'zeraSaldo'

export default {
  name: 'Cobranca',
  data: () => ({
    isLoading: false,
    loadTable: false,
    snackbar: {
      toggle: false,
      type: null,
      msg: null
    },

    headers: [
      { align: 'start', class: 'table-header', text: 'COD', value: 'entity_cod' },
      { align: 'start', class: 'table-header', text: 'Nome', value: 'entity_name' },
      { align: 'start', class: 'table-header', text: 'Valor', value: 'deve_atual' },
    ]
  }),

  mounted () {
    this.loadTable = true
    let self = this

    self.isLoading = true
    this.getItens()
      .then(() => {
      })
      .catch((e) => {
        self.snackbar.toggle = true
        self.snackbar.msg = e.error
        self.snackbar.type = 'error'
      })
      .finally(() => {
        this.loadTable = false
        self.isLoading = false
      })
  },

  computed: {
    ...mapGetters(storeNameSpace, ['getData']),
    totalRevenda () {
      return size(this.getData)
    },

    totalValorZeraSaldo () {
      return this.getData.reduce((acc, curr) => {
        return acc + parseFloat(curr.deve_atual)
      }, 0)
    }
  },

  methods: {
    ...mapActions(storeNameSpace, ['getItens', 'zeraSaldo', 'clearData']),
    submit () {
      let self = this     
      this.$swal({
        icon: 'warning',
        text: `Tem certeza que deseja zerar os saldos de ${this.totalRevenda} revendas no valor total de ${currency(this.totalValorZeraSaldo)}`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.value) {
          this.isLoading = true
          
          self.zeraSaldo().then(()=>{
            successSnackbar('Zera saldo realizado com sucesso!')
            this.clearData()
          }).catch((err)=>{
            errorSnackbar(err.error)
          })
          .finally(() => {
            this.isLoading = false
          })
        }
      }) 
      
    }
  }
}
</script>

<style lang="scss" src="@/assets/styles/view/usuarios-web.scss"></style>

